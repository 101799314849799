@import 'include-media';

/* Material */

button.mat-button,
button.mat-flat-button,
button.mat-raised-button {
  line-height: var(--height-input);
  min-width: 100px;
  // font-size: 13px;

  &.mat-info {
    background-color: var(--color-info);
  }

  &.mat-warn {
    background-color: var(--color-warn);
  }
}

.mat-simple-snackbar-action button.mat-button {
  line-height: unset;
}

.mat-card {
  padding: 1.5rem;
  background-color: var(--color-background);

  &:not([class*='mat-elevation-z']) {
    box-shadow: var(--shadow);
  }

  @include media('<desktop') {
    padding: 0.5rem;
  }
}

.mat-menu-panel {
  box-shadow: var(--shadow-menu) !important;
  min-height: auto !important;
}

.mat-tooltip {
  font-size: 12px;
  background-color: var(--color-background-accent);
  padding: 8px 15px !important;
  font-weight: 600;
}

.mat-table {
  background: transparent;
  width: 100%;

  thead th {
    background-color: var(--color-background-2);
  }

  tbody td,
  thead th {
    &:first-child {
      border-left: 0px !important;
    }
  }

  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    padding: 0px 16px;

    &:first-of-type {
      width: 40px;
      padding: 0px 12px;
    }

    &:last-of-type {
      padding: 0px 12px;
    }
  }

  tr.mat-row,
  tr.mat-footer-row {
    height: 40px;
  }

  tbody tr.mat-row {
    transition: background-color 150ms ease;

    &:hover {
      background-color: var(--color-background-2);
    }
  }

  .mat-header-cell {
    cursor: default;
    border-radius: 0px !important;
    color: #373737;
    font-weight: 600 !important;
    border-left: 1px solid var(--color-cell-border);
    border-left: 1px solid var(--color-cell-border);
  }
}

tr.mat-header-row {
  height: 31px !important;
}

thead {
  tr {
    th {
      background-color: #f6f6f6 !important;
    }
  }
}

.mat-menu-item {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-between !important;
  padding: 12px;
  gap: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;

  i {
    font-size: 16px;
  }
}

.mat-cell {
  border-left: 1px solid var(--color-cell-border);
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--color-primary);
}

.mat-checkbox-frame {
  border: 1px solid #afafaf !important;
  border-radius: 4px !important;
  width: 16px;
  height: 16px;
}

.mat-sort-header-container {
  display: grid !important;
  grid-auto-flow: column !important;
  justify-content: space-between !important;
  align-items: center !important;
  grid-template-columns: 1fr;
  cursor: default !important;

  p {
    font-weight: 600;
    margin: 0px;
  }
}

.mat-sort-header-content {
  justify-content: space-between !important;
}

.mat-sort-header-arrow[style] {
  // Hide default arrow stem
  .mat-sort-header-stem {
    display: none;
  }
  .mat-sort-header-indicator {
    opacity: 1;
    color: black;
    font-weight: bold;

    // Hide default arrow as its composed of left, right and middle
    .mat-sort-header-pointer-left,
    .mat-sort-header-pointer-right,
    .mat-sort-header-pointer-middle {
      display: none;
    }
  }
}

// My custom ascending arrow
[aria-sort='ascending'] {
  .mat-sort-header-arrow {
    .mat-sort-header-indicator {
      &::before {
        display: block !important;
        content: '\25B4';
        top: -0em;
        position: absolute;
        color: #004e87;
        font-size: 24px;
        line-height: 16px;
      }
    }
  }
}

// My custom descending arrow
[aria-sort='descending'] {
  .mat-sort-header-arrow {
    .mat-sort-header-indicator {
      &::before {
        display: block !important;

        content: '\25BE';
        top: -15px;
        position: absolute;
        color: #004e87;
        font-size: 24px;
      }
    }
  }
}

.mat-paginator-container {
  flex-direction: row-reverse;
}

/* Paginate css classes

.mat-paginator-range-actions {
}

.mat-paginator-page-size {
}

.mat-paginator-navigation-next {
}

.mat-paginator-navigation-previous {
}
*/

.mat-paginator {
  border-radius: 8px;
  background: var(--color-background-2);
}

.mat-paginator-range-label {
  margin: 3px 0 0 4px !important;
}

mat-expansion-panel {
  background: transparent !important;

  &.no-styling {
    box-shadow: none !important;
    overflow: visible !important;

    &.mat-expanded .mat-expansion-panel-content:not(.ng-animating) {
      overflow: visible !important;
    }

    .mat-expansion-panel-content {
      overflow: hidden !important;
    }

    .mat-expansion-panel-body {
      padding: 0 !important;
    }
  }
}

.mat-button-toggle-group {
  align-self: flex-start;
  border-radius: 0;

  .mat-button-toggle-label-content {
    font-size: 13px;
    line-height: 28px;
  }

  .mat-button-toggle-checked {
    background-color: var(--color-accent);
    color: white;
    font-weight: 600;
  }
}

// Other components

.ng-select {
  .ng-select-container {
    height: var(--height-input) !important;
    min-height: var(--height-input) !important;
    font-size: 14px;
    font-family: var(--font-family);
    transition: border-color 150ms ease, border-radius 150ms ease, background-color 150ms ease;
    background-color: var(--color-background-2);
    color: var(--color-text);
    border-radius: var(--border-radius) !important;
    cursor: text;
    gap: 18px;

    // border: 1px solid transparent;
    border: 1px solid hsla(0, 0%, 69%, 1);
    // border-top-color: transparent;
    // border-left-color: transparent;
    // border-right-color: transparent;

    .ng-value-container {
      .ng-value {
        width: 100%;
      }
    }

    .ng-arrow-wrapper .ng-arrow {
      border-color: none !important;
      border-style: none !important;
      border-width: 0 !important;
    }

    .ng-arrow-wrapper {
      background-image: url('/assets/images/icons/arrow-head-down.svg');
      background-repeat: no-repeat;
    }

    .ng-value-container {
      padding-left: 16px;

      .ng-placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #afafaf;
      }

      .ng-input {
        padding-left: 1rem !important;
        padding-right: calc(40px + 1rem) !important;

        input {
          height: 28px;
        }
      }
    }
  }

  &.select-http-filter {
    .ng-select-container {
      height: 40px !important;
      min-height: 40px !important;
    }
  }

  &.ng-untouched {
    .ng-select-container {
      border-color: hsla(0, 0%, 86%, 1);
    }
  }

  &.ng-select-opened {
    .ng-arrow-wrapper {
      background-image: url('/assets/images/icons/arrow-head-up.svg');
      background-repeat: no-repeat;
    }
  }

  &.ng-select-multiple {
    .ng-select-container .ng-value-container .ng-input {
      padding-left: 10px !important;
      padding-right: 0 !important;
      padding-bottom: 6px !important;
    }

    .ng-select-container .ng-value-container .ng-placeholder {
      top: initial;
      padding-left: 10px;
    }
  }

  &.ng-select-opened,
  &.ng-select-focused {
    .ng-select-container {
      border: 1px solid var(--color-primary-focus) !important;
      box-shadow: none !important;
      background-color: var(--color-background-2);
    }
  }

  .ng-arrow-wrapper {
    padding-right: 0.8rem;
    width: 32px;
  }

  .ng-arrow {
    transition: var(--transition-slow);
    border-color: var(--color-icon-input) transparent transparent !important;
  }

  &.custom {
    .ng-clear-wrapper {
      display: inline-flex;
      margin-top: 3.5px;

      .ng-clear {
        /* Hide default cross */
        font-size: 0;
      }

      .ng-clear::before {
        /* Display close icon */
        content: '';
        width: 20px;
        height: 20px;
        display: inline-block;
        background-image: url('/assets/images/icons/close-round-background.svg');
      }
    }
  }

  &.ng-select-opened > .ng-select-container .ng-arrow {
    border-width: 5px 5px 2.5px;
    transform: rotateX(180deg);
  }

  // Custom class for multiple styling
  .ng-select-multiple-label {
    display: flex;
    background-color: var(--color-background-accent);
    color: white;
    border-radius: 20px;
    margin-top: -4px;

    .number {
      padding: 6px 3px 6px 10px;
      font-weight: 500;
    }

    .clear {
      height: 24px;
      width: 24px;
      margin-top: 2px;
      margin-right: 2px;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      cursor: pointer;
      transition: var(--transition);
      padding-bottom: 1px;

      &:hover {
        background-color: var(--color-background-accent-hover);
      }
    }
  }
}

.ng-select-disabled > .ng-select-container {
  color: #636363;
  background-color: #f9f9f9 !important;
}

.ng-dropdown-panel {
  box-shadow: var(--shadow-menu) !important;
  border-radius: var(--border-radius) !important;
  margin-top: 4px !important;
  padding: 14px 16px;
  border: 1px solid #afafaf !important;
  box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.06) !important;

  .ng-dropdown-panel-items {
    .ng-option {
      justify-content: space-between;
    }
  }

  .ng-dropdown-header {
    padding: 0px;
    margin-bottom: 11px;
    border-bottom: 0px;
  }

  .scroll-host {
    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-button {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #dbdbdbee;
    }
  }

  .ng-dropdown-panel-items {
    padding-right: 12px;
  }

  .ng-option {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex !important;
    align-items: center;
    min-height: var(--height-input);
    padding: 12px 0px !important;
    text-align: left;
    text-decoration: none;
    cursor: pointer;
    outline: 0;
    -webkit-tap-highlight-color: transparent;
    transition: var(--transition);
    border-bottom: 1px solid #e6e6e6;

    &:first-child {
      border-radius: var(--border-radius) var(--border-radius) 0 0;
    }

    &:last-child {
      border-radius: 0 0 var(--border-radius) var(--border-radius);
      border-bottom: none;
    }

    &.ng-option-marked {
      background-color: transparent !important;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04) !important;
      }
    }

    &.ng-option-selected {
      background-color: transparent !important;

      &::after {
        content: '';
        width: 20px;
        height: 20px;
        margin-left: 8px;
        display: inline-block;
        background-image: url('/assets/images/icons/check-thin.svg');
      }
    }

    .image-option {
      margin: -4px 0;
    }
  }
}

// Emoji mart

.emoji-mart-search input {
  border: 1px solid transparent;
}

.emoji-mart-search-icon {
  top: 11px;
}

.emoji-mart-scroll {
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    background-color: var(--color-background-2);
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: var(--color-background-2);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #959595;
    border-radius: 10px;
  }
}

.mat-dialog-container {
  padding: 0px !important;
  border-radius: 0px !important;
}

.menu-wrapper {
  padding: 0px;
  background-color: #373737 !important;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
  border-radius: 4px;

  button {
    &:hover {
      background-color: #272727 !important;
    }
  }

  &.status-menu-wrapper {
    margin-left: 8px !important;

    .mat-menu-content {
      min-width: 125px !important;
    }
  }

  &.category-menu-wrapper {
    margin-left: 8px !important;
    padding: 8px 8px 8px 0;

    .mat-menu-content {
      min-width: 125px !important;
      max-height: 432px !important;
      overflow-y: auto !important;
      scrollbar-width: thin !important;

      &::-webkit-scrollbar {
        height: 2px; /* height of horizontal scrollbar */
        width: 2px; /* width of vertical scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: #373737;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        border: 3px solid #dbdbdb;
      }
    }
  }

  .mat-menu-submenu-icon {
    color: #ffffff;
    width: 4.5px;
    height: 7.5px;
  }
}
