.dialog-form {
  .save-changes-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;
    background: var(--color-accent);
    color: #ffffff;
    height: 40px;
    border: 1px solid #e6e6e6;
    margin-bottom: 16px;
    width: 100%;

    &:disabled {
      background: #e6e6e6;

      span {
        font-weight: 500;
        font-size: 14px;
        color: #ababab;
      }
    }

    span {
      font-weight: 500;
      font-size: 14px;
      color: #ffffff;
    }
  }

  .log-out-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;
    border-top: 1px solid #dbdbdb;
    height: 40px;
    width: 100%;
    margin-left: 0px !important;

    span {
      font-weight: 500;
      font-size: 14px;
      color: #515151;
    }
  }

  .user-image-and-details {
    display: flex;
    gap: 36px;
  }

  .user-name-details {
    display: flex;
    gap: 12px;
  }

  .user-image-camera {
    display: flex;
    width: 140px;
    height: 140px;
    border-radius: 140px;
    background: #f6f6f6;
  }

  .user-name-details-type {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 336px;
  }

  .user-email-password {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
    margin-top: 36px;
  }

  & ~ .mat-dialog-actions {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 0px;
  }

  & ~ .mat-dialog-actions .mat-button-base + .mat-button-base,
  & ~ .mat-dialog-actions .mat-mdc-button-base + .mat-mdc-button-base {
    margin-left: 0px;
  }

  & ~ .mat-dialog-actions .mat-button {
    width: 100%;
  }
}

.change-password {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  height: 40px;
  border: 1px solid #dbdbdb;
  width: fit-content;
  background-color: transparent;

  p,
  span {
    padding: 0px;
    font-size: 14px;
    font-weight: 600;
    color: #515151;
  }
}

.change-password-form {
  display: grid;
  grid-auto-flow: row;
  gap: 16px;
  width: 480px;
}

.mat-dialog-content {
  display: initial !important;
}

.dialog-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #373737;
    margin-bottom: 0px;
  }
}

.close-button {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  margin-right: -4px;

  &:focus {
    border: none;
  }

  &:focus-visible {
    outline: none;
  }
}
