@import './styles/theme-overrides';
@import './styles/base-styles';
@import './styles/helpers';
@import './styles/components/profile-view';
@import './styles/components/dialog';
@import './styles/components/tab-navigation';
@import './styles/components/table';
@import 'include-media';

// Global variables
:root {
  // --font-family: 'Roboto', 'Segoe UI', 'Helvetica', sans-serif;
  --font-family: 'Inter', 'Segoe UI', 'Helvetica', sans-serif;

  --color-primary: #272727;
  --color-primary-hover: #4b4b4b;
  --color-primary-active: #515151;
  --color-primary-focus: var(--color-primary-active);
  --color-accent: hsla(32, 46%, 52%, 1);
  --color-warn: #cd5252;
  --color-info: #bd884b;
  --color-success: #54b676;
  --color-border: rgba(0, 0, 0, 0.2);

  --color-primary-text: white;
  --color-secondary-text: hsla(0, 0%, 100%, 1);
  --color-text: hsl(0, 0%, 10%);
  --color-text-fade: hsl(0, 0%, 60%);
  --color-icon-input: #636363;

  --color-background: #f6f6f6;
  --color-background-2: hsl(0, 0%, 100%);
  --color-background-2-hover: hsl(0, 0%, 86%);
  --color-background-accent: hsla(32, 46%, 52%, 1);
  --color-background-accent-hover: hsla(32, 46%, 52%, 0.5);
  --color-cell-border: rgb(197, 197, 197);

  --shadow: 0px 1px 15px 1px rgba(65, 65, 65, 0.1);
  --shadow-menu: 0px 4px 20px 1px rgba(65, 65, 65, 0.15), 0px 4px 8px -3px rgba(65, 65, 65, 0.3);
  --border-radius: 0px;
  --transition: all 150ms ease;
  --transition-slow: all 300ms ease;

  --height-input: 48px;
  --height-toolbar: 56px;

  --border-login-form: 2px;
  --main-section-padding: 32px;
}

.card {
  padding: 1.5rem;
  box-shadow: var(--shadow);
  // border: 2px solid var(--color-background-2);
  border-radius: 8px;

  @include media('<tablet') {
    padding: 0.5rem;
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  position: relative;

  p.prefix,
  p.suffix {
    font-weight: 600;
  }

  .prefix {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
    color: var(--color-icon-input);
    margin: 0;

    & ~ input {
      padding-left: 42px;
    }
  }

  .suffix {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    color: var(--color-icon-input);
    margin: 0;

    & ~ input {
      padding-right: 48px;
    }

    &.clear-icon {
      font-size: 15px;
      cursor: pointer;
      transition: var(--transition);

      &:hover {
        color: var(--color-warn);
      }
    }
  }

  button.suffix {
    height: 32px;
    width: 32px;
    line-height: 32px;

    i {
      font-size: 20px;
    }
  }

  label {
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #636363;
  }

  &:focus-within label {
    color: #373737 !important;
  }
}

.page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;

  @include media('<tablet') {
    justify-content: flex-start;
    flex-wrap: wrap;

    .header-tools {
      margin-top: 0.7rem;
      width: 100%;
    }
  }

  app-back {
    margin-right: 1rem;
  }

  h1 {
    margin: 0;
    flex-grow: 1;
  }

  .header-tools {
    display: flex;

    > *:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

.header-tools {
  button {
    height: 40px;
  }
}

.table-wrapper {
  width: 100%;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);

  .table-selection {
    width: fit-content;
    display: grid;
    grid-auto-flow: column;
    gap: 16px;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
    color: var(--color-primary-active);
    height: 56px;
    max-height: 0;
    transition: var(--transition-slow);
    font-size: 14px;

    > span {
      width: max-content;
      font-weight: 400;
      color: hsla(0, 0%, 32%, 1);
      font-size: 12px;
    }

    .actions {
      > *:not(:last-child) {
        margin-right: 0.5rem;
      }
    }

    &.visible {
      max-height: 56px;

      & + .table-container .mat-table thead th {
        &:first-child {
          border-radius: 0 0 0 8px;
        }

        &:last-child {
          border-radius: 0 0 8px 0;
        }
      }
    }
  }

  .table-container {
    width: 100%;
    overflow: auto;
    min-height: 100px;
  }
}

.snackbar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  padding: 8px 12px;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  min-width: 89px;
  min-height: 36px;
  width: auto;

  .mat-simple-snackbar-action {
    button {
      height: 40px;
      width: 40px;
      padding: 0;

      .mat-button-wrapper {
        color: white;
        font-size: 18px;
      }
    }
  }
}

.success-snackbar {
  background-color: var(--color-success);
  color: white;
}

.error-snackbar {
  background-color: var(--color-warn);
  color: white;
}

/* 
  Needed for displaying asterisk icon on password
*/
@font-face {
  font-family: 'fontello';
  src: url('./assets/fonts/fontello/fontello.eot?28877721');
  src: url('./assets/fonts/fontello/fontello.eot?28877721#iefix') format('embedded-opentype'),
    url('./assets/fonts/fontello/fontello.woff2?28877721') format('woff2'),
    url('./assets/fonts/fontello/fontello.woff?28877721') format('woff'),
    url('./assets/fonts/fontello/fontello.ttf?28877721') format('truetype'),
    url('./assets/fonts/fontello/fontello.svg?28877721#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.flex-row-wrapper {
  display: flex;
  flex-direction: row;
}

.flex-column-wrapper {
  display: flex;
  flex-direction: column;
}

.grid-row-wrapper {
  display: grid;
  grid-auto-flow: row;
}

.grid-column-wrapper {
  display: grid;
  grid-auto-flow: column;
}

.hide-element-display {
  display: none !important;
}

.user-image {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.w100 {
  width: 100%;
}

.search-and-table-selection {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 5px;
}

.selection-wrapper {
  display: grid;
  grid-auto-flow: column;
  gap: 8px;

  button {
    padding: 8px 12px;
    background: #e6e6e6;
    border-radius: 4px;
    height: 32px;
    width: max-content;
    display: grid;
    grid-auto-flow: column;
    align-content: center;
    align-items: center;
    border: none;
    cursor: pointer;

    span {
      font-weight: 600;
      font-size: 14px;
      color: #272727;
    }
  }

  .view-as-button {
    width: max-content;
  }
}

.column-style {
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
  padding: 16px;
}

.column-style-no-box-shadow {
  background: #ffffff;
  padding: 16px;
}

.column-style-only-box-shadow {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
}

.search-bar-add-user-wrapper {
  padding: 16px 16px 0px 8px;
  gap: 16px;
}

.flex-row-wrapper .w100 {
  img {
    border-radius: 240px;
  }
}

input {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid var(--color-background-2-hover);

  &[type='password'] {
    font-family: 'fontello';
    color: #272727;
    letter-spacing: 1px;
    font-size: 10px;

    &::placeholder {
      font-size: 14px;
    }
  }

  &:focus {
    border-width: 1px;
  }

  &::placeholder {
    font-family: var(--font-family);
    color: #afafaf;
    letter-spacing: 0.5px;
  }
}

.entry-page {
  display: grid;
  place-content: center;

  img {
    height: 20px;
    width: 86px;
    margin: 0 auto 62px;
  }

  section {
    display: flex;
    flex-direction: column;
    padding: 36px 40px;
    width: 440px;
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);

    > * + * {
      margin-top: 20px;
    }
  }

  input {
    background: #ffffff;
    border: 2px solid #dbdbdb;
    border-radius: 0px;
  }

  button {
    height: 48px;
    padding: 12px 16px;
    gap: 8px;
    height: 48px;
    background: #bd884b !important;
    color: #ffffff !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border: none;
    cursor: pointer;

    &:disabled {
      background: #e6e6e6 !important;
      color: #afafaf !important;
      cursor: not-allowed;
    }
  }

  & > section {
    .join-business-error {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #272727;
    }

    & > .form-group {
      & p {
        &.title,
        &:first-child {
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          text-align: center;
          color: #373737;
          margin: 0px;
          margin-bottom: 32px;
        }

        &.highlight {
          color: #bd884b;
          text-transform: capitalize;
        }

        span {
          color: #373737;
        }
      }

      &.is-login-page {
        & ~ button {
          margin-top: 32px;

          & ~ p {
            margin-top: 32px;
          }
        }
      }

      .email {
        border: 1px solid #dbdbdb;
        border-radius: 16px;
        margin: 32px 0 36px 0;
        height: 28px;
        width: fit-content;
        padding: 4px 16px;
        align-self: center;

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #858585;
        }
      }

      &:focus-within {
        label {
          color: #373737;
        }

        input {
          border: var(--border-login-form) solid #515151 !important;
          padding-top: -1px;
        }
      }

      label {
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: #858585;
      }

      & ~ p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #373737;
        font-weight: 600;
        cursor: pointer;
        margin-bottom: 0px;
      }

      & + p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #858585;
        cursor: default;
        text-align: left;
      }
    }
  }
}

.password-box {
  display: flex;
  flex-direction: column;
}

i,
img {
  &.eye-icon,
  img.eye-icon {
    background-color: transparent;
    height: 100%;
    padding-right: 8px;

    position: absolute;
    top: 18px;
    bottom: 0;
    height: 24px;
    font-size: 25px;
    margin: auto;
    right: 10px;
    text-align: right;

    color: #373737;
    cursor: pointer;
  }
}

.text-capitalize {
  text-transform: capitalize;
}

.selection-wrapper {
  button {
    span {
      font-weight: 600;
    }
  }
}

.image-option {
  display: flex;
  flex-direction: row;
  align-items: center;

  app-avatar {
    margin-right: 8px;
  }

  p {
    text-transform: capitalize;
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #373737;
  }

  img,
  i {
    width: 24px !important;
    height: 24px !important;
  }
}

.remove-border {
  border: none !important;
}

.grid-center-column-items {
  display: grid;
  align-items: center;
  grid-auto-flow: column;
}

.grid-center-row-items {
  display: grid;
  align-items: center;
  grid-auto-flow: row;
}

.justify-between {
  justify-content: space-between;
}

.justify-start {
  justify-content: flex-start;
}

.no-button-styling {
  background-color: transparent;
  border: none;
}

.filter-table-row-arrow {
  display: grid;
  align-items: center;
  border: none;
  background-color: transparent;

  & > i {
    padding: 4px;
    width: 20px;
    height: 20px;
    background-image: url('/assets/images/icons/filter-table-row-arrow.svg');
    background-repeat: no-repeat;

    &:hover {
      background-image: url('/assets/images/icons/filter-table-row-arrow-hover.svg');
      background-repeat: no-repeat;
    }
  }
}

@mixin rotate-item($value) {
  transform: rotate($value);
}

.rotate-180 {
  @include rotate-item(180deg);
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.no-margin {
  margin: 0px;
}

.thin-scrollbar {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 2px; /* height of horizontal scrollbar */
    width: 2px; /* width of vertical scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    border: 3px solid #dbdbdb;
  }
}

.relative {
  position: relative;
}

.table-wrapper,
.tab-wrapper {
  .name-display {
    font-weight: 700;
    font-size: 12px;
    color: #373737;
    padding: 6px 0px;

    &.is-audio {
      padding: 0px;
    }
  }
}

.custom-assets-wrapper {
  display: grid;
  background-color: #fff;
  align-content: flex-start;
  height: fit-content;
  padding: 16px;
  gap: 24px;

  .assets-wrapper {
    display: grid;
    grid-auto-flow: row;
  }

  .no-assets {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #515151;
  }
}

.video-duration {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  gap: 10px;
  position: absolute;
  width: fit-content;
  height: 20px;
  left: 8px;
  bottom: 8px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px); /* Note: backdrop-filter has minimal browser support */
  border-radius: 20px;

  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
  }
}
