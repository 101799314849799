.section-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.user-details-wrapper {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  padding: 20px 16px 20px 24px;
  min-height: 240px;
}

.user-photo-and-divided-details {
  display: flex;
  align-items: center;
  gap: 40px;

  img {
    align-self: center !important;
    border-radius: 240px !important;
    width: 180px !important;
    height: 180px !important;
    object-fit: cover !important;
  }
}

.user-name {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #272727;
}

.user-occupation {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #858585;
}

.user-last-activity {
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  color: #858585;
}

.user-divided-details {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: center !important;
  gap: 8px !important;
  margin-bottom: 20px;
}

.status-and-last-activity {
  align-items: center;
  gap: 12px;
}

.flex-row-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.flex-row-container > .flex-row-item {
  flex: 1 1 30%; /*grow | shrink | basis */
}

.flex-row-item {
  display: flex;
  background: #ffffff;
  flex-direction: column;
  gap: 8px;

  p {
    &:nth-child(1) {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #373737;
    }

    &:nth-child(2) {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #636363;
    }
  }
}
