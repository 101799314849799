.mat-column-status,
.mat-column-lastActivity,
.mat-column-createdAt {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #636363 !important;
}

.mat-column-notableInfo,
.mat-column-category {
  width: 240px;
}

.mat-column-preview {
  width: 116px;
  padding: 0px 8px !important;
}

.mat-column-status {
  width: 120px;
}

.mat-column-notableInfo {
  width: 240px;
}

.mat-column-createdAt {
  width: 200px;
}
