.tab-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .mat-tab-links {
    gap: 12px !important;
  }

  /* Styles for tab labels */
  .mat-tab-label,
  .mat-tab-link {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #272727;
    opacity: 1 !important;
    padding: 4px 12px !important;
    margin: 0px !important;
    min-width: 94.67px !important;
  }

  /* Styles for the active tab label */
  // .mat-tab-label.mat-tab-label-active {
  //   color: #272727;
  // }

  /* Styles for the ink bar */
  .mat-ink-bar {
    height: 1px !important;
    background: #272727 !important;
    border-radius: 2px !important;
    visibility: visible !important;
    opacity: 1 !important;
  }

  .inside-tab-wrapper {
    .assets-assign-tabs {
      background-color: #f6f6f6;
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      border-bottom: 1px solid dbdbdb;

      .tab-button {
        background: #ffffff;
        padding: 11px 21px 9px 21px;
        margin-bottom: -1px;
        border: none;

        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #636363;
        }

        &.active {
          box-shadow: 0 8px 6px #fff, 0 1px 3px rgba(0, 0, 0, 0.16);
          border: 1px solid #dbdbdb;
          border-bottom: 1px solid #fff;
          margin-bottom: -1px;
          padding: 11px 20px 9px 20px;

          span {
            color: #272727;
          }
        }
      }
    }
  }

  .add-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 12px !important;
    border-radius: 0px !important;
    background-color: var(--color-accent) !important;
  }

  .mat-column-profilePictureUrl {
    /*
      First column of table needs this exact padding top and bottom
    */
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
}
